import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
//import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";

export default function BlogpostTemplate({ data }) {
    //deckDeckGoHighlightElement();
    const post = data.markdownRemark;
    let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;
    return (
        <Layout currentPage="blog">
            <SEO title={post.frontmatter.title} />
            {/* <Img
                fluid={featuredImgFluid}
                style={{
                    position: "absolute",
                    width: "100%",
                    top: "0px",
                    zIndex: "-100",
                }}
            /> */}
            <BackgroundImage
                Tag="div"
                className="background-section"
                fluid={featuredImgFluid}
                backgroundColor={`#040e18`}
                id="1"
                role="img"
                objectFit="contain"
                objectPosition="50% 50%"
            >
                <div
                    className="container z-depth-2 padding-20"
                    style={{
                        background: "rgba(255,255,255,1)",
                        marginTop: "0px",
                        marginBottom: "0px",
                    }}
                >
                    <div
                        className="row align-items-center"
                        style={{ marginTop: "20px" }}
                    >
                        <div className="col" style={{ paddingLeft: "30px" }}>
                            <h1
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    paddingBottom: "10px",
                                    borderBottom: "1px solid #02799E",
                                }}
                            >
                                {post.frontmatter.title}
                            </h1>
                            <p className="atab-text">
                                <span>
                                    {post.frontmatter.date}{" "}
                                    {post.frontmatter.author}
                                </span>
                                <br />
                                <span>
                                    u kategoriji{" "}
                                    <b>{post.frontmatter.category}</b>
                                </span>
                            </p>
                            <p className="atab-text"></p>
                        </div>
                        {/* <div className="col-auto">
                            <Link
                                to="/blog/"
                                className="atab darken-1 btn waves-effect waves-light"
                            >
                                Nazad
                            </Link>
                        </div> */}
                    </div>
                    <div className="row  padding-20">
                        <div className="col">
                            <div
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            ></div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </Layout>
    );
}

export const postQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                author
                date
                category
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 10) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
